var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-wrapper"},[(_vm.episode)?_vm._l((_vm.episode),function(single,index){return _c('div',{key:index,staticClass:"card"},[_c('h3',{staticClass:"ep-number"},[_c('button',{staticClass:"copy-link",on:{"click":function($event){return _vm.copyToClipboard(_vm.$route.path)}}},[_c('fa',{attrs:{"icon":['fas', 'link']}})],1),_c('span',[_vm._v(_vm._s(_vm.episodeNumber(single.title)))])]),_c('h3',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.formatTitle(single.title)))])]),_c('p',{staticClass:"pubdate"},[_vm._v(_vm._s(_vm.formatDate(single.pubdate)))]),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(single.description))]),_c('AudioPlayer',{attrs:{"url":single.enclosure.link,"type":single.enclosure.type,"objectid":single.guid}})],1)}):(_vm.items.length)?_vm._l((_vm.items),function(episode,index){return _c('div',{key:index,staticClass:"card"},[_c('h3',{staticClass:"ep-number"},[_c('button',{staticClass:"copy-link",on:{"click":function($event){return _vm.copyToClipboard(_vm.$route.path)}}},[_c('fa',{attrs:{"icon":['fas', 'link']}})],1),_c('span',[_vm._v(_vm._s(_vm.episodeNumber(episode.title)))])]),_c('h3',{staticClass:"title"},[(_vm.$route.name !== 'Details')?_c('router-link',{staticClass:"episode-link",attrs:{"to":{ 
                    name: 'Details', 
                    params: { 
                        episodeId: _vm.formatLink(episode.title),
                        title: _vm.formatTitle(episode.title),
                        epNum: _vm.episodeNumber(episode.title),
                        pubdate: _vm.formatDate(episode.pubDate),
                        desc: episode.description,
                        url: episode.enclosure.link,
                        type: episode.enclosure.type,
                        objectid: episode.guid,
                    }}}},[_c('span',[_vm._v(_vm._s(_vm.formatTitle(episode.title)))]),_c('fa',{attrs:{"icon":['fas', 'external-link-alt']}})],1):_c('span',[_vm._v(_vm._s(_vm.formatTitle(episode.title)))])],1),_c('p',{staticClass:"pubdate"},[_vm._v(_vm._s(_vm.formatDate(episode.pubdate)))]),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(episode.description))]),_c('AudioPlayer',{attrs:{"url":episode.enclosure.link,"type":episode.enclosure.type,"objectid":episode.guid}})],1)}):[_c('p',{staticClass:"empty"},[_vm._v("No results found...")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }